import axios from 'axios'
import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls'

const BETWEEN = 'BETWEEN'
const EQUAL = 'EQUAL'
const IN = 'IN'
const LIKE = 'LIKE'
const NOT_EQUAL = 'NOT_EQUAL'

export { BETWEEN, EQUAL, IN, LIKE, NOT_EQUAL }


function _toFilters(data) {
  let filters = []
  if (data)
    for (let [fieldName, value] of Object.entries(data)) {
      let add = { fieldName }
      if (value instanceof Array) {
        let [operator, value1] = value
        add.operatorType = operator
        add.value1 = value1 instanceof Array ? value1.join('|') : value1
      } else {
        add.operatorType = EQUAL
        add.value1 = value
      }
      filters.push(add)
    }
  return filters
}

export const fetchCodes = filters => new Promise((resolve, reject) => {
  axios(CodeMaintainanceApiUrls.MAINTAINANCE_CODE, {
    method: 'post',
    data: {
      baseFilterDTO: {
        filterType: "AND",
        filterProperties: _toFilters({
          active: true,
          ...filters
        }),
        sortProperties: []
      },
      codeMType: "CodeMasterCode"
    },
    headers: { Authorization: `Bearer ${sessionStorage.userToken}`}
  }).then(res =>
    res.status === 200 ? resolve(res.data) : reject(res)
  ).catch(err => reject(err))
})

function isEmpty(value) {
  return value === null || value === undefined || value === ''
}

function truncLongFileName(fileName) {
  const maxFileName = 24
  if (!isEmpty(fileName) && fileName.lastIndexOf('.') > maxFileName) {
    let fileNameSplit = fileName.split('.')

    let format = fileNameSplit[fileNameSplit.length - 1]
    return fileName.substring(0, maxFileName).trim() + '...' + format
  } else {
    return fileName
  }
}

const isValidHexColor = (hex) => /^#([0-9A-F]{3}){1,2}$/i.test(hex)

function hexToRgba(hexColor, alpha) {
  let hex = isValidHexColor(hexColor) ? hexColor.substring(1) : hexColor
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((hex) => hex + hex)
      .join('')
  }
  const rgb =
    parseInt(hex.slice(0, 2), 16) +
    ', ' +
    parseInt(hex.slice(2, 4), 16) +
    ', ' +
    parseInt(hex.slice(4, 6), 16)
  return alpha ? 'rgba(' + rgb + ', ' + alpha + ')' : 'rgb(' + rgb + ')'
}

const Utils = Object.freeze({
  truncLongFileName: truncLongFileName,
  hexToRgba: hexToRgba
})

export default Utils

import { constants, useTranslation } from 'cng-web-lib'

import AccessLevelCompanyCustomRuleKeys from 'src/constants/locale/key/AccessLevelCompanyCustomRule'
import AccessLevelCompanyKeys from 'src/constants/locale/key/AccessLevelCompany'
import AccessLevelMasterKeys from 'src/constants/locale/key/AccessLevelMaster'
import AccessLevelRuleKeys from 'src/constants/locale/key/AccessLevelRule'
import AccessLevelUserKeys from 'src/constants/locale/key/AccessLevelUser'
import BatchBookingRequestKeys from 'src/constants/locale/key/BatchBookingRequest'
import BatchSftpProcessKeys from 'src/constants/locale/key/BatchSftpProcess'
import BatchMilestoneKeys from 'src/constants/locale/key/BatchMilestone'
import CarrierEnrollKeys from 'src/constants/locale/key/CarrierEnroll'
import ClogsConfigKeys from 'src/constants/locale/key/ClogsConfig'
import CompanyForShipperRequestKeys from 'src/constants/locale/key/CompanyForShipperRequest'
import CompanyForUserGroupKeys from 'src/constants/locale/key/CompanyForUserGroup'
import CompanyPreferenceKeys from 'src/constants/locale/key/CompanyPreference'
import FreightBookingKeys from 'src/constants/locale/key/FreightBooking'
import Namespace from 'src/constants/locale/Namespace'
import PartyMappingKeys from 'src/constants/locale/key/PartyMapping'
import PollingKeys from 'src/constants/locale/key/Polling'
import RiceStockKeys from 'src/constants/locale/key/RiceStock'
import SCOJob from 'src/constants/locale/key/SCOJob'
import SearchScheduleKeys from 'src/constants/locale/key/SearchSchedule'
import ShipperRequestTypeKeys from 'src/constants/locale/key/ShipperRequestType'
import ShippingInstructionRequestKeys from 'src/constants/locale/key/ShippingInstructionRequest'
import SubscriptionKeys from 'src/constants/locale/key/Subscription'
import TcalAsUserGroupMasterKeys from 'src/constants/locale/key/TcalAsUserGroupMaster'
import TcalPartyCarrierCodeKeys from 'src/constants/locale/key/TcalPartyCarrierCode'
import TcalVsManageCarriersKeys from 'src/constants/locale/key/TcalVsManageCarriers'
import TcalVsVesselKeys from 'src/constants/locale/key/TcalVsVessel'
import TcalVsVoyageKeys from 'src/constants/locale/key/TcalVsVoyage'
import TtfbTprPartyKeys from 'src/constants/locale/key/TtfbTprParty'
import UserForShipperRequestKeys from 'src/constants/locale/key/UserForShipperRequest'
import pathMap from 'src/paths/pathMap'
import ForwardHubKeys from 'src/constants/locale/key/ForwardHub'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import CecAccountKeys from 'src/constants/locale/key/CecAccount'
import NbsMappingKeys from 'src/constants/locale/key/NbsMapping'
import CodeMappingKeys from 'src/constants/locale/key/CodeMapping'

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function useBreadcrumbNameMap() {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.USER_FOR_SHIPPER_REQUEST,
    Namespace.COMPANY_FOR_USER_GROUP,
    Namespace.COMPANY_FOR_SHIPPER_REQUEST,
    Namespace.SHIPPER_REQUEST_TYPE,
    Namespace.ACCESS_LEVEL_USER,
    Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
    Namespace.ACCESS_LEVEL_COMPANY,
    Namespace.ACCESS_LEVEL_RULE,
    Namespace.ACCESS_LEVEL_MASTER,
    Namespace.TCAL_VS_VESSEL,
    Namespace.TCAL_VS_VOYAGE,
    Namespace.SEARCH_SCHEDULE,
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    Namespace.SUBSCRIPTION,
    Namespace.TCAL_PARTY_CARRIER_CODE,
    Namespace.TCAL_AS_USER_GROUP_MASTER,
    Namespace.TTFB_TPR_PARTY,
    Namespace.TTFB_TPR_USER,
    Namespace.FREIGHT_BOOKING,
    Namespace.PARTY_MAPPING,
    Namespace.BATCH_BOOKING_REQUEST,
    Namespace.SHIPPING_INSTRUCTION_REQUEST,
    Namespace.BATCH_MILESTONE,
    Namespace.BATCH_SFTP_PROCESS,
    Namespace.SCOJob,
    Namespace.SCOJobConfigKeys,
    Namespace.CLOGS_CONFIG,
    Namespace.COMPANY_PREFERENCE,
    Namespace.FORWARD_HUB,
    Namespace.SCO_ADMIN,
    Namespace.CEC_ACCOUNT,
		Namespace.CODE_MAPPING
  ])
  const translatedTextsObject = makeTranslatedTextsObject(translate)

  const breadcrumbNameMap = new Map()

  // --------------- Forward-Hub Product Mangement --------------
  breadcrumbNameMap.set(
    pathMap.PROD_MGT_LIST_VIEW,
    translatedTextsObject.prodMgtListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.PROD_MGT_ADD_VIEW,
    translatedTextsObject.prodMgtAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.PROD_MGT_EDIT_VIEW,
    translatedTextsObject.prodMgtEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.PROD_MGT_DETAILS_VIEW,
    translatedTextsObject.prodMgtDetailsViewTitle
  )
  // --------------- End Forward-Hub Product Mangement --------------



  breadcrumbNameMap.set(
    pathMap.DASH_LOAD_STATUS_LIST_VIEW,
    translatedTextsObject.dashLoadStatusListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.DASH_LOAD_STATUS_ADD_VIEW,
    translatedTextsObject.dashLoadStatusAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.DASH_LOAD_STATUS_EDIT_VIEW,
    translatedTextsObject.dashLoadStatusEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.DASH_LOAD_STATUS_DETAILS_VIEW,
    translatedTextsObject.dashLoadStatusDetailsViewTitle
  )

  // --------------- User For Shipper Request Type --------------
  breadcrumbNameMap.set(
    pathMap.USER_FOR_SHIPPER_REQUEST_LIST_VIEW,
    translatedTextsObject.userForShipperRequestListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.USER_FOR_SHIPPER_REQUEST_ADD_VIEW,
    translatedTextsObject.userForShipperRequestAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.USER_FOR_SHIPPER_REQUEST_EDIT_VIEW,
    translatedTextsObject.userForShipperRequestEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.USER_FOR_SHIPPER_REQUEST_DETAILS_VIEW,
    translatedTextsObject.userForShipperRequestDetailsViewTitle
  )
  // --------------- End User For Shipper Request Type --------------

  // --------------- Company User Group --------------
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_USER_GROUP_LIST_VIEW,
    translatedTextsObject.companyForUserGroupListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_USER_GROUP_ADD_VIEW,
    translatedTextsObject.companyForUserGroupAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_USER_GROUP_EDIT_VIEW,
    translatedTextsObject.companyForUserGroupEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_USER_GROUP_DETAILS_VIEW,
    translatedTextsObject.companyForUserGroupDetailsViewTitle
  )
  // --------------- End Company User Group --------------

  // --------------- Company Shipper Request Type --------------
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_SHIPPER_REQUEST_LIST_VIEW,
    translatedTextsObject.companyForShipperRequestListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_SHIPPER_REQUEST_ADD_VIEW,
    translatedTextsObject.companyForShipperRequestAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_SHIPPER_REQUEST_EDIT_VIEW,
    translatedTextsObject.companyForShipperRequestEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_FOR_SHIPPER_REQUEST_DETAILS_VIEW,
    translatedTextsObject.companyForShipperRequestDetailsViewTitle
  )
  // --------------- End Company Shipper Request Type --------------

  // --------------- Shipper Request Type --------------
  breadcrumbNameMap.set(
    pathMap.SHIPPER_REQUEST_TYPE_LIST_VIEW,
    translatedTextsObject.shipperRequestTypeListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SHIPPER_REQUEST_TYPE_ADD_VIEW,
    translatedTextsObject.shipperRequestTypeAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SHIPPER_REQUEST_TYPE_EDIT_VIEW,
    translatedTextsObject.shipperRequestTypeEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SHIPPER_REQUEST_TYPE_DETAILS_VIEW,
    translatedTextsObject.shipperRequestTypeDetailsViewTitle
  )
  // --------------- End Shipper Request Type --------------

  // --------------- Access Level User --------------
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_USER_LIST_VIEW,
    translatedTextsObject.accessLevelUserListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_USER_ADD_VIEW,
    translatedTextsObject.accessLevelUserAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_USER_EDIT_VIEW,
    translatedTextsObject.accessLevelUserEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_USER_DETAILS_VIEW,
    translatedTextsObject.accessLevelUserDetailsViewTitle
  )
  // --------------- End Access Level User --------------

  // --------------- Access level company custom rule --------------
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_LIST_VIEW,
    translatedTextsObject.accessLevelCompanyCustomRuleListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_ADD_VIEW,
    translatedTextsObject.accessLevelCompanyCustomRuleAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_EDIT_VIEW,
    translatedTextsObject.accessLevelCompanyCustomRuleEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_CUSTOM_RULE_DETAILS_VIEW,
    translatedTextsObject.accessLevelCompanyCustomRuleDetailsViewTitle
  )
  // --------------- End Access level company custom rule --------------

  // --------------- Eco Calculator------------------------

  // breadcrumbNameMap.set(
  //   pathMap.ECO_CALCULATOR_LIST_VIEW,
  //   translatedTextsObject.ecoCalculatorViewTitle
  // )

  // --------------- End Eco Calculator -------------------

  // --------------- Access level company --------------
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_LIST_VIEW,
    translatedTextsObject.accessLevelCompanyListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_ADD_VIEW,
    translatedTextsObject.accessLevelCompanyAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_EDIT_VIEW,
    translatedTextsObject.accessLevelCompanyEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_COMPANY_DETAILS_VIEW,
    translatedTextsObject.accessLevelCompanyDetailsViewTitle
  )
  // --------------- End access level company --------------

  // --------------- Access level rule --------------
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_RULE_LIST_VIEW,
    translatedTextsObject.accessLevelRuleListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_RULE_ADD_VIEW,
    translatedTextsObject.accessLevelRuleAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_RULE_EDIT_VIEW,
    translatedTextsObject.accessLevelRuleEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_RULE_DETAILS_VIEW,
    translatedTextsObject.accessLevelRuleDetailsViewTitle
  )
  // --------------- End access level rule --------------

  // --------------- Access level master --------------
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_MASTER_LIST_VIEW,
    translatedTextsObject.accessLevelMasterListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_MASTER_ADD_VIEW,
    translatedTextsObject.accessLevelMasterAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_MASTER_EDIT_VIEW,
    translatedTextsObject.accessLevelMasterEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.ACCESS_LEVEL_MASTER_DETAILS_VIEW,
    translatedTextsObject.accessLevelMasterDetailsViewTitle
  )
  // --------------- End Access level master --------------

  // --------------- TPR User --------------
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_USER_LIST_VIEW,
    translatedTextsObject.ttfbTprUserListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_USER_ADD_VIEW,
    translatedTextsObject.ttfbTprUserAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_USER_EDIT_VIEW,
    translatedTextsObject.ttfbTprUserEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_USER_DETAILS_VIEW,
    translatedTextsObject.ttfbTprUserDetailsViewTitle
  )
  // --------------- EndTPR User --------------

  // --------------- TPR Party --------------
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_PARTY_LIST_VIEW,
    translatedTextsObject.ttfbTprPartyListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_PARTY_ADD_VIEW,
    translatedTextsObject.ttfbTprPartyAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_PARTY_EDIT_VIEW,
    translatedTextsObject.ttfbTprPartyEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TTFB_TPR_PARTY_DETAILS_VIEW,
    translatedTextsObject.ttfbTprPartyDetailsViewTitle
  )
  // --------------- End TPR Party --------------

  // --------------- user group master --------------
  breadcrumbNameMap.set(
    pathMap.TCAL_AS_USER_GROUP_MASTER_LIST_VIEW,
    translatedTextsObject.tcalAsUserGroupMasterListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_AS_USER_GROUP_MASTER_ADD_VIEW,
    translatedTextsObject.tcalAsUserGroupMasterAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_AS_USER_GROUP_MASTER_EDIT_VIEW,
    translatedTextsObject.tcalAsUserGroupMasterEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_AS_USER_GROUP_MASTER_DETAILS_VIEW,
    translatedTextsObject.tcalAsUserGroupMasterDetailsViewTitle
  )
  // --------------- enduser group master --------------

  // --------------- carrier code --------------
  breadcrumbNameMap.set(
    pathMap.TCAL_PARTY_CARRIER_CODE_LIST_VIEW,
    translatedTextsObject.tcalPartyCarrierCodeListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_PARTY_CARRIER_CODE_ADD_VIEW,
    translatedTextsObject.tcalPartyCarrierCodeAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_PARTY_CARRIER_CODE_EDIT_VIEW,
    translatedTextsObject.tcalPartyCarrierCodeEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_PARTY_CARRIER_CODE_DETAILS_VIEW,
    translatedTextsObject.tcalPartyCarrierCodeDetailsViewTitle
  )
  // --------------- end carrier code --------------

  // --------------- Subscription --------------
  breadcrumbNameMap.set(
    pathMap.SUBSCRIPTION_LIST_VIEW,
    translatedTextsObject.subscriptionListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SUBSCRIPTION_ADD_VIEW,
    translatedTextsObject.subscriptionAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SUBSCRIPTION_EDIT_VIEW,
    translatedTextsObject.subscriptionEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SUBSCRIPTION_DETAILS_VIEW,
    translatedTextsObject.subscriptionDetailsViewTitle
  )
  // ---------------End Subscription --------------

  // --------------- Vessel Schedule --------------
  // vessel
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VESSEL_LIST_VIEW,
    translatedTextsObject.tcalVsVesselListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VESSEL_ADD_VIEW,
    translatedTextsObject.tcalVsVesselAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VESSEL_EDIT_VIEW,
    translatedTextsObject.tcalVsVesselEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VESSEL_DETAILS_VIEW,
    translatedTextsObject.tcalVsVesselDetailsViewTitle
  )
  // voyage
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VOYAGE_LIST_VIEW,
    translatedTextsObject.tcalVsVoyageListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VOYAGE_ADD_VIEW,
    translatedTextsObject.tcalVsVoyageAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VOYAGE_EDIT_VIEW,
    translatedTextsObject.tcalVsVoyageEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_VOYAGE_DETAILS_VIEW,
    translatedTextsObject.tcalVsVoyageDetailsViewTitle
  )

  // --------------- VS Code Mapping --------------
  breadcrumbNameMap.set(
		pathMap.CODE_MAPPING_LIST_VIEW,
		translatedTextsObject.codeMappingListViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CODE_MAPPING_ADD_VIEW,
		translatedTextsObject.codeMappingAddViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CODE_MAPPING_EDIT_VIEW,
		translatedTextsObject.codeMappingEditViewTitle
	)
	breadcrumbNameMap.set(
		pathMap.CODE_MAPPING_DETAILS_VIEW,
		translatedTextsObject.codeMappingDetailsViewTitle
	)

  // --------------- Schedule Search --------------
  breadcrumbNameMap.set(
    pathMap.SEARCH_SCHEDULE_VIEW,
    translatedTextsObject.searchScheduleListViewTitle
  )

  // --------------- Manage Preferred Carrier --------------
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_MANAGE_PREF_CAR_LIST_VIEW,
    translatedTextsObject.tcalVsManagePreferredCarrierTitle
  )
  // --------------- Manage Blacklisted Carriers --------------
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_MANAGE_BLA_CAR_LIST_VIEW,
    translatedTextsObject.tcalVsManageBlacklistedCarriersTitle
  )
  // --------------- Manage Preferred Port Pairs --------------
  breadcrumbNameMap.set(
    pathMap.TCAL_VS_MANAGE_PREF_PP_LIST_VIEW,
    translatedTextsObject.tcalVsManagePreferredPortPairsTitle
  )
  // --------------- Freight Booking --------------
  // New Booking
  breadcrumbNameMap.set(
    pathMap.NEW_BOOKING,
    translatedTextsObject.freightBookingNewBookingTitle
  )

  // ---------------- EDI Party mapping ---------------

  breadcrumbNameMap.set(
    pathMap.PARTY_MAPPING_LIST_VIEW,
    translatedTextsObject.partyMappingListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.PARTY_MAPPING_ADD_VIEW,
    translatedTextsObject.partyMappingAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.PARTY_MAPPING_EDIT_VIEW,
    translatedTextsObject.partyMappingEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.PARTY_MAPPING_DETAILS_VIEW,
    translatedTextsObject.partyMappingDetailsViewTitle
  )

  // ---------------- EDI Batch Booking Request ---------------
  breadcrumbNameMap.set(
    pathMap.BATCH_BOOKING_REQUEST_LIST_VIEW,
    translatedTextsObject.batchBookingRequestListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_BOOKING_REQUEST_ADD_VIEW,
    translatedTextsObject.batchBookingRequestAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_BOOKING_REQUEST_EDIT_VIEW,
    translatedTextsObject.batchBookingRequestEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_BOOKING_REQUEST_DETAILS_VIEW,
    translatedTextsObject.batchBookingRequestDetailsViewTitle
  )

  // ---------------- EDI Shipping Instruction Request ---------------
  breadcrumbNameMap.set(
    pathMap.SHIPPING_INSTRUCTION_REQUEST_LIST_VIEW,
    translatedTextsObject.shippingInstructionRequestListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SHIPPING_INSTRUCTION_REQUEST_ADD_VIEW,
    translatedTextsObject.shippingInstructionRequestAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SHIPPING_INSTRUCTION_REQUEST_EDIT_VIEW,
    translatedTextsObject.shippingInstructionRequestEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SHIPPING_INSTRUCTION_REQUEST_DETAILS_VIEW,
    translatedTextsObject.shippingInstructionRequestDetailsViewTitle
  )

  // ---------------- EDI Batch SFTP Process ---------------
  breadcrumbNameMap.set(
    pathMap.BATCH_SFTP_PROCESS_LIST_VIEW,
    translatedTextsObject.batchSftpProcessListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_SFTP_PROCESS_ADD_VIEW,
    translatedTextsObject.batchSftpProcessAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_SFTP_PROCESS_EDIT_VIEW,
    translatedTextsObject.batchSftpProcessEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_SFTP_PROCESS_DETAILS_VIEW,
    translatedTextsObject.batchSftpProcessDetailsViewTitle
  )

  // ---------------- EDI Batch Milestone ---------------
  breadcrumbNameMap.set(
    pathMap.BATCH_MILESTONE_LIST_VIEW,
    translatedTextsObject.batchMilestoneListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_MILESTONE_ADD_VIEW,
    translatedTextsObject.batchMilestoneAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_MILESTONE_EDIT_VIEW,
    translatedTextsObject.batchMilestoneEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.BATCH_MILESTONE_DETAILS_VIEW,
    translatedTextsObject.batchMilestoneDetailsViewTitle
  )

  


  // --------------- SCO Job --------------
  breadcrumbNameMap.set(
    pathMap.SCO_JOB_CONFIG,
    translatedTextsObject.scoJobConfig
  )
  breadcrumbNameMap.set(
    pathMap.SCO_JOB_CONFIG,
    translatedTextsObject.scoJobConfigAdd
  )
  breadcrumbNameMap.set(
    pathMap.SCO_JOB_CONFIG,
    translatedTextsObject.scoJobConfigEdit
  )
  breadcrumbNameMap.set(
    pathMap.SCO_JOB_CONFIG,
    translatedTextsObject.scoJobConfigView
  )
  // --------------- End SCO Job --------------   return breadcrumbNameMap

  // ---------------- Logistics Admin Clogs Config ---------------
  breadcrumbNameMap.set(
    pathMap.CLOGS_CONFIG_LIST_VIEW,
    translatedTextsObject.clogsConfigListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CLOGS_CONFIG_ADD_VIEW,
    translatedTextsObject.clogsConfigAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CLOGS_CONFIG_EDIT_VIEW,
    translatedTextsObject.clogsConfigEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CLOGS_CONFIG_DETAILS_VIEW,
    translatedTextsObject.clogsConfigDetailsViewTitle
  )

  breadcrumbNameMap.set(
		pathMap.CLOGS_CONFIG_DETAILS_VIEW,
		translatedTextsObject.billingAuditListViewTitle
	)
  // ---------------- Logistics Admin Clogs Config ---------------

  // ---------------- Freight Booking Company Preference ---------------
  breadcrumbNameMap.set(
    pathMap.COMPANY_PREFERENCE_LIST_VIEW,
    translatedTextsObject.companyPreferenceListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_PREFERENCE_ADD_VIEW,
    translatedTextsObject.companyPreferenceAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_PREFERENCE_EDIT_VIEW,
    translatedTextsObject.companyPreferenceEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.COMPANY_PREFERENCE_DETAILS_VIEW,
    translatedTextsObject.companyPreferenceDetailsViewTitle
  )
  // ---------------- Freight Booking Company Preference ---------------
  // ---------------- SCO Admin---------------
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_CODE_TYPE_LIST_VIEW,
    translatedTextsObject.scoAdminCodeTypeListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_CODE_TYPE_ADD_VIEW,
    translatedTextsObject.scoAdminCodeTypeAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_CODE_TYPE_EDIT_VIEW,
    translatedTextsObject.scoAdminCodeTypeEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_CODE_TYPE_DETAILS_VIEW,
    translatedTextsObject.scoAdminCodeTypeDetailsViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_COUNTRY_CODE_LIST_VIEW,
    translatedTextsObject.scoAdminCountryCodeListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_TIMEZONE_LIST_VIEW,
    translatedTextsObject.scoAdminTimeZoneListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_STATE_CODE_COUNTRY_LIST_VIEW,
    translatedTextsObject.scoAdminStateCodeCountryListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_STATE_CODE_STATE_LIST_VIEW,
    translatedTextsObject.scoAdminStateCodeStateListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CEC_ACCOUNT_LIST_VIEW,
    translatedTextsObject.cecAccountListViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CEC_ACCOUNT_ADD_VIEW,
    translatedTextsObject.cecAccountAddViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CEC_ACCOUNT_EDIT_VIEW,
    translatedTextsObject.cecAccountEditViewTitle
  )
  breadcrumbNameMap.set(
    pathMap.CEC_ACCOUNT_DETAILS_VIEW,
    translatedTextsObject.cecAccountDetailsViewTitle
  )


  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_PRINCIPAL_MAPPING,
    translatedTextsObject.scoAdminPrincipalMappingTitle
  )

  breadcrumbNameMap.set(
    pathMap.SCO_ADMIN_PRINCIPAL_MAPPING_PARTIES_EDIT_VIEW,
    translatedTextsObject.scoAdminPrincipalMappingEditTitle
  )

  return breadcrumbNameMap
}

function makeTranslatedTextsObject(translate) {
  // ---------------- EDI Batch Booking Request ---------------
  let batchBookingRequest = translate(
    Namespace.BATCH_BOOKING_REQUEST,
    BatchBookingRequestKeys.TITLE
  )
  let batchBookingRequestListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: batchBookingRequest
    }
  )
  let batchBookingRequestAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: batchBookingRequest
    }
  )
  let batchBookingRequestEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: batchBookingRequest
    }
  )
  let batchBookingRequestDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: batchBookingRequest
    }
  )

  // ---------------- EDI Shipping Instruction Request ---------------
  let shippingInstructionRequest = translate(
    Namespace.SHIPPING_INSTRUCTION_REQUEST,
    ShippingInstructionRequestKeys.TITLE
  )
  let shippingInstructionRequestListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: shippingInstructionRequest
    }
  )
  let shippingInstructionRequestAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: shippingInstructionRequest
    }
  )
  let shippingInstructionRequestEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: shippingInstructionRequest
    }
  )
  let shippingInstructionRequestDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: shippingInstructionRequest
    }
  )

  // ---------------- EDI Batch SFTP Process ---------------
  let batchSftpProcess = translate(
    Namespace.BATCH_SFTP_PROCESS,
    BatchSftpProcessKeys.TITLE
  )
  let batchSftpProcessListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: batchSftpProcess
    }
  )
  let batchSftpProcessAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: batchSftpProcess
    }
  )
  let batchSftpProcessEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: batchSftpProcess
    }
  )
  let batchSftpProcessDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: batchSftpProcess
    }
  )

  // ---------------- EDI Batch Milestone ---------------
  let batchMilestone = translate(
    Namespace.BATCH_MILESTONE,
    BatchMilestoneKeys.TITLE
  )
  let batchMilestoneListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: batchMilestone
    }
  )
  let batchMilestoneAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: batchMilestone
    }
  )
  let batchMilestoneEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: batchMilestone
    }
  )
  let batchMilestoneDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: batchMilestone
    }
  )

  // ---------------- EDI NBS Mapping----------------
  let nbsMapping = translate(
    Namespace.NBS_MAPPING,
    NbsMappingKeys.TITLE
  )
let nbsMappingListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: nbsMapping
    }
  )
let nbsMappingAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: nbsMapping
    }
  )
let nbsMappingEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: nbsMapping
    }
  )
let nbsMappingDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: nbsMapping
    }
  )

  // ---------------- EDI Party mapping ---------------
  let partyMapping = translate(Namespace.PARTY_MAPPING, PartyMappingKeys.TITLE)
  let partyMappingListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: partyMapping
    }
  )
  let partyMappingAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: partyMapping
    }
  )
  let partyMappingEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: partyMapping
    }
  )
  let partyMappingDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: partyMapping
    }
  )

  // --------------- User for Shipper Request Type --------------
  let userForShipperRequest = translate(
    Namespace.USER_FOR_SHIPPER_REQUEST,
    UserForShipperRequestKeys.TITLE
  )
  let userForShipperRequestListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: userForShipperRequest
    }
  )
  let userForShipperRequestAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: userForShipperRequest
    }
  )
  let userForShipperRequestEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: userForShipperRequest
    }
  )
  let userForShipperRequestDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: userForShipperRequest
    }
  )
  // --------------- End User for Shipper Request Type --------------

  //---------------- Carbon Footprint ----------------

  // let ecoCalculatorViewTitle = translate(
  //   Namespace.UI_COMPONENT,
  //   UiComponentKeys.Breadcrumbs.
  // )

  //-----------------End Carbon Footprint -------------

  // --------------- Company User Group --------------
  let companyForUserGroup = translate(
    Namespace.COMPANY_FOR_USER_GROUP,
    CompanyForUserGroupKeys.TITLE
  )
  let companyForUserGroupListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: companyForUserGroup
    }
  )
  let companyForUserGroupAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: companyForUserGroup
    }
  )
  let companyForUserGroupEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: companyForUserGroup
    }
  )
  let companyForUserGroupDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: companyForUserGroup
    }
  )
  // --------------- End Company User Group --------------

  // --------------- Company Shipper Request Type --------------
  let companyForShipperRequest = translate(
    Namespace.COMPANY_FOR_SHIPPER_REQUEST,
    CompanyForShipperRequestKeys.TITLE
  )
  let companyForShipperRequestListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: companyForShipperRequest
    }
  )
  let companyForShipperRequestAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: companyForShipperRequest
    }
  )
  let companyForShipperRequestEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: companyForShipperRequest
    }
  )
  let companyForShipperRequestDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: companyForShipperRequest
    }
  )
  // --------------- End Company Shipper Request Type --------------

  // --------------- Shipper Request Type --------------
  let shipperRequestType = translate(
    Namespace.SHIPPER_REQUEST_TYPE,
    ShipperRequestTypeKeys.TITLE
  )
  let shipperRequestTypeListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: shipperRequestType
    }
  )
  let shipperRequestTypeAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: shipperRequestType
    }
  )
  let shipperRequestTypeEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: shipperRequestType
    }
  )
  let shipperRequestTypeDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: shipperRequestType
    }
  )
  // --------------- End Shipper Request Type --------------

  // --------------- Access Level User --------------
  let accessLevelUser = translate(
    Namespace.ACCESS_LEVEL_USER,
    AccessLevelUserKeys.TITLE
  )
  let accessLevelUserListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: accessLevelUser
    }
  )
  let accessLevelUserAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: accessLevelUser
    }
  )
  let accessLevelUserEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: accessLevelUser
    }
  )
  let accessLevelUserDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: accessLevelUser
    }
  )
  // --------------- End Access Level User --------------

  // --------------- Access level company custom rule --------------
  let accessLevelCompanyCustomRule = translate(
    Namespace.ACCESS_LEVEL_COMPANY_CUSTOM_RULE,
    AccessLevelCompanyCustomRuleKeys.TITLE
  )
  let accessLevelCompanyCustomRuleListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: accessLevelCompanyCustomRule
    }
  )
  let accessLevelCompanyCustomRuleAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: accessLevelCompanyCustomRule
    }
  )
  let accessLevelCompanyCustomRuleEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: accessLevelCompanyCustomRule
    }
  )
  let accessLevelCompanyCustomRuleDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: accessLevelCompanyCustomRule
    }
  )
  // --------------- End Access level company custom rule --------------

  // --------------- Access level company --------------
  let accessLevelCompany = translate(
    Namespace.ACCESS_LEVEL_COMPANY,
    AccessLevelCompanyKeys.TITLE
  )
  let accessLevelCompanyListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: accessLevelCompany
    }
  )
  let accessLevelCompanyAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: accessLevelCompany
    }
  )
  let accessLevelCompanyEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: accessLevelCompany
    }
  )
  let accessLevelCompanyDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: accessLevelCompany
    }
  )
  // --------------- End access level company --------------

  // --------------- Access level rule --------------
  let accessLevelRule = translate(
    Namespace.ACCESS_LEVEL_RULE,
    AccessLevelRuleKeys.TITLE
  )
  let accessLevelRuleListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: accessLevelRule
    }
  )
  let accessLevelRuleAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: accessLevelRule
    }
  )
  let accessLevelRuleEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: accessLevelRule
    }
  )
  let accessLevelRuleDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: accessLevelRule
    }
  )
  // --------------- End access level rule --------------

  // --------------- Access level master --------------
  let accessLevelMaster = translate(
    Namespace.ACCESS_LEVEL_MASTER,
    AccessLevelMasterKeys.TITLE
  )
  let accessLevelMasterListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: accessLevelMaster
    }
  )
  let accessLevelMasterAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: accessLevelMaster
    }
  )
  let accessLevelMasterEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: accessLevelMaster
    }
  )
  let accessLevelMasterDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: accessLevelMaster
    }
  )
  // --------------- End Access level master --------------

  // --------------- TPR User --------------
  let ttfbTprUser = translate(Namespace.TTFB_TPR_USER, 'User')
  let ttfbTprUserListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: ttfbTprUser
    }
  )
  let ttfbTprUserAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: ttfbTprUser
    }
  )
  let ttfbTprUserEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: ttfbTprUser
    }
  )
  let ttfbTprUserDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: ttfbTprUser
    }
  )
  // --------------- End TPR User --------------

  // --------------- TPR Party --------------
  let ttfbTprParty = translate(Namespace.TTFB_TPR_PARTY, TtfbTprPartyKeys.TITLE)
  let ttfbTprPartyListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: ttfbTprParty
    }
  )
  let ttfbTprPartyAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: ttfbTprParty
    }
  )
  let ttfbTprPartyEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: ttfbTprParty
    }
  )
  let ttfbTprPartyDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: ttfbTprParty
    }
  )
  // --------------- EndTPR Party --------------

  // --------------- user group master --------------
  let tcalAsUserGroupMaster = translate(
    Namespace.TCAL_AS_USER_GROUP_MASTER,
    TcalAsUserGroupMasterKeys.TITLE
  )
  let tcalAsUserGroupMasterListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: tcalAsUserGroupMaster
    }
  )
  let tcalAsUserGroupMasterAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: tcalAsUserGroupMaster
    }
  )
  let tcalAsUserGroupMasterEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: tcalAsUserGroupMaster
    }
  )
  let tcalAsUserGroupMasterDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: tcalAsUserGroupMaster
    }
  )
  // --------------- end user group master --------------

  // --------------- carrier code --------------
  let tcalPartyCarrierCode = translate(
    Namespace.TCAL_PARTY_CARRIER_CODE,
    TcalPartyCarrierCodeKeys.TITLE
  )
  let tcalPartyCarrierCodeListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: tcalPartyCarrierCode
    }
  )
  let tcalPartyCarrierCodeAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: tcalPartyCarrierCode
    }
  )
  let tcalPartyCarrierCodeEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: tcalPartyCarrierCode
    }
  )
  let tcalPartyCarrierCodeDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: tcalPartyCarrierCode
    }
  )
  // --------------- end carrier code --------------

  // --------------- Subscription --------------
  let subscription = translate(Namespace.SUBSCRIPTION, SubscriptionKeys.TITLE)
  let subscriptionListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: subscription
    }
  )
  let subscriptionAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: subscription
    }
  )
  let subscriptionEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: subscription
    }
  )
  let subscriptionDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: subscription
    }
  )
  // ---------------End Subscription --------------

  // --------------- Vessel Schedule --------------
  // carrier enrollment
  let carrierEnroll = translate(
    Namespace.TCAL_VS_VESSEL,
    CarrierEnrollKeys.TITLE
  )
  let carrierEnrollListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: carrierEnroll
    }
  )
  let carrierEnrollAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: carrierEnroll
    }
  )
  let carrierEnrollEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: carrierEnroll
    }
  )
  let carrierEnrollDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: carrierEnroll
    }
  )
  //  polling
  let polling = translate(Namespace.POLLING, PollingKeys.TITLE)
  let pollingListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: polling
    }
  )
  let pollingAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: polling
    }
  )
  let pollingEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: polling
    }
  )
  let pollingDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: polling
    }
  )
  // vessel
  let tcalVsVessel = translate(Namespace.TCAL_VS_VESSEL, TcalVsVesselKeys.TITLE)
  let tcalVsVesselListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: tcalVsVessel
    }
  )
  let tcalVsVesselAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: tcalVsVessel
    }
  )
  let tcalVsVesselEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: tcalVsVessel
    }
  )
  let tcalVsVesselDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: tcalVsVessel
    }
  )
  // voyage
  let tcalVsVoyage = translate(Namespace.TCAL_VS_VOYAGE, TcalVsVoyageKeys.TITLE)
  let tcalVsVoyageListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: tcalVsVoyage
    }
  )
  let tcalVsVoyageAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: tcalVsVoyage
    }
  )
  let tcalVsVoyageEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: tcalVsVoyage
    }
  )
  let tcalVsVoyageDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: tcalVsVoyage
    }
  )

  // --------------- VS Code Mapping --------------
  let codeMapping = translate(
    Namespace.CODE_MAPPING,
    CodeMappingKeys.TITLE
  )
let codeMappingListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: codeMapping
    }
  )
let codeMappingAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: codeMapping
    }
  )
let codeMappingEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: codeMapping
    }
  )
let codeMappingDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: codeMapping
    }
  )

  // --------------- Schedule Search Start --------------
  let searchScheduleListViewTitle = translate(
    Namespace.SEARCH_SCHEDULE,
    SearchScheduleKeys.TITLE
  )
  // --------------- Schedule Search End --------------

  // --------------- Manage Preferred Carrier --------------
  let tcalVsManagePreferredCarrierTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    TcalVsManageCarriersKeys.PREFERRED_CARRIERS_TITLE
  )

  // --------------- Manage Blacklisted Carrier --------------
  let tcalVsManageBlacklistedCarriersTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    TcalVsManageCarriersKeys.BLACKLISTED_CARRIERS_TITLE
  )
  // --------------- Manage Preferred Port Pairs --------------
  let tcalVsManagePreferredPortPairsTitle = translate(
    Namespace.TCAL_VS_MANAGE_CARRIERS,
    TcalVsManageCarriersKeys.PREFERRED_PORT_PAIRS_TITLE
  )
  // --------------- Freight Booking --------------
  let freightBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.FREIGHT_BOOKING_TITLE
  )

  let freightBookingNewBookingTitle = translate(
    Namespace.FREIGHT_BOOKING,
    FreightBookingKeys.NEW_BOOKING_TITLE
  )

  // ---------------- Shipping Info ---------------

  // --------------- SCO Job --------------
  let scoJobConfig = translate(
    Namespace.SCOJob,
    SCOJob.JOB_CONFIGURATION_TABLE_NAME
  )

  let scoJobConfigAdd = translate(Namespace.SCOJob, SCOJob.SCO_JOB_CONFIG_ADD)

  let scoJobConfigEdit = translate(Namespace.SCOJob, SCOJob.SCO_JOB_CONFIG_EDIT)

  let scoJobConfigView = translate(Namespace.SCOJob, SCOJob.SCO_JOB_CONFIG_VIEW)

  // ---------------- SCO Job ---------------

  // ---------------- Logistics Admin Clogs Config ---------------
  let clogsConfig = translate(Namespace.CLOGS_CONFIG, ClogsConfigKeys.TITLE)
  let clogsConfigListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: clogsConfig
    }
  )
  let clogsConfigAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: clogsConfig
    }
  )
  let clogsConfigEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: clogsConfig
    }
  )
  let clogsConfigDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: clogsConfig
    }
  )

  let billingAuditListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: clogsConfig
    }
  )
  // ---------------- Logistics Admin Clogs Config ---------------

  // ---------------- Freight Booking Company Preference ---------------
  let companyPreference = translate(
    Namespace.COMPANY_PREFERENCE,
    CompanyPreferenceKeys.TITLE
  )
  let companyPreferenceListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: companyPreference
    }
  )
  let companyPreferenceAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: companyPreference
    }
  )
  let companyPreferenceEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: companyPreference
    }
  )
  let companyPreferenceDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: companyPreference
    }
  )
  // ---------------- Freight Booking Company Preference ---------------

  // ---------------- Forward Hub PROD_MGT ---------------
  let prodMgt = translate(Namespace.FORWARD_HUB, ForwardHubKeys.TITLE)
  let prodMgtListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: prodMgt
    }
  )
  let prodMgtAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: prodMgt
    }
  )
  let prodMgtEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: prodMgt
    }
  )
  let prodMgtDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: prodMgt
    }
  )
  // ---------------- Forward Hub PROD_MGT ---------------

  // ---------------- Forward HUB Load status monitoring -----
  let dashLoadStatus = translate(
    Namespace.DASH_LOAD_STATUS,
    ForwardHubKeys.TITLE
  )

  let dashLoadStatusListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: dashLoadStatus
    }
  )
  let dashLoadStatusAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: dashLoadStatus
    }
  )
  let dashLoadStatusEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: dashLoadStatus
    }
  )
  let dashLoadStatusDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: dashLoadStatus
    }
  )
  let scoAdminCodeMaster = translate(
    Namespace.SCO_ADMIN,
    SCOAdminKeys.CODE_MASTER_TITLE
  )

  let scoAdminCodeTypeListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: scoAdminCodeMaster
    }
  )

  let scoAdminCodeTypeAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: scoAdminCodeMaster
    }
  )

  let scoAdminCodeTypeTableViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: scoAdminCodeMaster
    }
  )

  let scoAdminCodeTypeEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: scoAdminCodeMaster
    }
  )

  let scoAdminCodeTypeDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: scoAdminCodeMaster
    }
  )
  let scoAdminTimeZone = translate(
    Namespace.SCO_ADMIN,
    SCOAdminKeys.TIMEZONE_TITLE
  )
  let scoAdminTimeZoneListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: scoAdminTimeZone
    }
  )
  let scoAdminCountryStateCode = translate(
    Namespace.SCO_ADMIN,
    SCOAdminKeys.COUNTRY_STATE_CODE_TITLE
  )
  let scoAdminStateCodeCountryListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: scoAdminCountryStateCode
    }
  )
  let scoAdminStateCodeStateListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: scoAdminCountryStateCode
    }
  )
  let scoAdminCountryCode = translate(
    Namespace.SCO_ADMIN,
    SCOAdminKeys.COUNTRY_CODE_TITLE
  )

  let scoAdminCountryCodeListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: scoAdminCountryCode
    }
  )
  let cecAccount = translate(
    Namespace.CEC_ACCOUNT,
    CecAccountKeys.CEC_ACCOUNT_TITLE
  )
  let cecAccountListViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: cecAccount
    }
  )
  let cecAccountAddViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.ADD_PAGE_TITLE,
    {
      title: cecAccount
    }
  )
  let cecAccountTableViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: cecAccount
    }
  )
  let cecAccountEditViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: cecAccount
    }
  )
  let cecAccountDetailsViewTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.VIEW_PAGE_TITLE,
    {
      title: cecAccount
    }
  )

  let scoAdminPrincipalMapping = translate(
    Namespace.SCO_ADMIN,
    SCOAdminKeys.PRINCIPAL_MAPPING
  )

  let scoAdminPrincipalMappingTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.TABLE_PAGE_TITLE,
    {
      title: scoAdminPrincipalMapping
    }
  )

  let scoAdminPrincipalMappingDetails = translate(
    Namespace.SCO_ADMIN,
    SCOAdminKeys.PRINCIPAL_MAPPING_DETIALS
  )

  let scoAdminPrincipalMappingEditTitle = translate(
    Namespace.UI_COMPONENT,
    UiComponentKeys.Breadcrumbs.EDIT_PAGE_TITLE,
    {
      title: scoAdminPrincipalMappingDetails
    }
  )

  return {
    prodMgtListViewTitle,
    prodMgtAddViewTitle,
    prodMgtEditViewTitle,
    prodMgtDetailsViewTitle,
    carrierEnrollListViewTitle,
    carrierEnrollAddViewTitle,
    carrierEnrollEditViewTitle,
    carrierEnrollDetailsViewTitle,
    pollingListViewTitle,
    pollingAddViewTitle,
    pollingEditViewTitle,
    pollingDetailsViewTitle,
    tcalVsVesselListViewTitle,
    tcalVsVesselAddViewTitle,
    tcalVsVesselEditViewTitle,
    tcalVsVesselDetailsViewTitle,
    tcalVsVoyageListViewTitle,
    tcalVsVoyageAddViewTitle,
    tcalVsVoyageEditViewTitle,
    tcalVsVoyageDetailsViewTitle,
    searchScheduleListViewTitle,
    tcalVsManagePreferredCarrierTitle,
    tcalVsManageBlacklistedCarriersTitle,
    tcalVsManagePreferredPortPairsTitle,
    subscriptionListViewTitle,
    subscriptionAddViewTitle,
    subscriptionEditViewTitle,
    subscriptionDetailsViewTitle,
    tcalPartyCarrierCodeListViewTitle,
    tcalPartyCarrierCodeAddViewTitle,
    tcalPartyCarrierCodeEditViewTitle,
    tcalPartyCarrierCodeDetailsViewTitle,
    tcalAsUserGroupMasterListViewTitle,
    tcalAsUserGroupMasterAddViewTitle,
    tcalAsUserGroupMasterEditViewTitle,
    tcalAsUserGroupMasterDetailsViewTitle,
    ttfbTprPartyListViewTitle,
    ttfbTprPartyAddViewTitle,
    ttfbTprPartyEditViewTitle,
    ttfbTprPartyDetailsViewTitle,
    ttfbTprUserListViewTitle,
    ttfbTprUserAddViewTitle,
    ttfbTprUserEditViewTitle,
    ttfbTprUserDetailsViewTitle,
    accessLevelMasterListViewTitle,
    accessLevelMasterAddViewTitle,
    accessLevelMasterEditViewTitle,
    accessLevelMasterDetailsViewTitle,
    accessLevelRuleListViewTitle,
    accessLevelRuleAddViewTitle,
    accessLevelRuleEditViewTitle,
    accessLevelRuleDetailsViewTitle,
    accessLevelCompanyListViewTitle,
    accessLevelCompanyAddViewTitle,
    accessLevelCompanyEditViewTitle,
    accessLevelCompanyDetailsViewTitle,
    accessLevelCompanyCustomRuleListViewTitle,
    accessLevelCompanyCustomRuleAddViewTitle,
    accessLevelCompanyCustomRuleEditViewTitle,
    accessLevelCompanyCustomRuleDetailsViewTitle,
    accessLevelUserListViewTitle,
    accessLevelUserAddViewTitle,
    accessLevelUserEditViewTitle,
    accessLevelUserDetailsViewTitle,
    shipperRequestTypeListViewTitle,
    shipperRequestTypeAddViewTitle,
    shipperRequestTypeEditViewTitle,
    shipperRequestTypeDetailsViewTitle,
    companyForShipperRequestListViewTitle,
    companyForShipperRequestAddViewTitle,
    companyForShipperRequestEditViewTitle,
    companyForShipperRequestDetailsViewTitle,
    companyForUserGroupListViewTitle,
    companyForUserGroupAddViewTitle,
    companyForUserGroupEditViewTitle,
    companyForUserGroupDetailsViewTitle,
    userForShipperRequestListViewTitle,
    userForShipperRequestAddViewTitle,
    userForShipperRequestEditViewTitle,
    userForShipperRequestDetailsViewTitle,
    freightBookingTitle,
    freightBookingNewBookingTitle,
    partyMappingListViewTitle,
    partyMappingAddViewTitle,
    partyMappingEditViewTitle,
    partyMappingDetailsViewTitle,
    batchBookingRequestListViewTitle,
    batchBookingRequestAddViewTitle,
    batchBookingRequestEditViewTitle,
    batchBookingRequestDetailsViewTitle,
    batchMilestoneListViewTitle,
    batchMilestoneAddViewTitle,
    batchMilestoneEditViewTitle,
    batchMilestoneDetailsViewTitle,
    nbsMappingListViewTitle,
		nbsMappingAddViewTitle,
		nbsMappingEditViewTitle,
		nbsMappingDetailsViewTitle,
    batchSftpProcessListViewTitle,
    batchSftpProcessAddViewTitle,
    batchSftpProcessEditViewTitle,
    batchSftpProcessDetailsViewTitle,
    scoJobConfig,
    scoJobConfigAdd,
    scoJobConfigEdit,
    scoJobConfigView,
    clogsConfigListViewTitle,
    clogsConfigAddViewTitle,
    clogsConfigEditViewTitle,
    clogsConfigDetailsViewTitle,
    companyPreferenceListViewTitle,
    companyPreferenceAddViewTitle,
    companyPreferenceEditViewTitle,
    companyPreferenceDetailsViewTitle,
    scoAdminCodeTypeListViewTitle,
    scoAdminCodeTypeAddViewTitle,
    scoAdminCodeTypeEditViewTitle,
    scoAdminCodeTypeDetailsViewTitle,
    scoAdminCountryCodeListViewTitle,
    scoAdminTimeZoneListViewTitle,
    scoAdminStateCodeCountryListViewTitle,
    scoAdminStateCodeStateListViewTitle,
    scoAdminCodeTypeTableViewTitle,
    cecAccount,
    cecAccountListViewTitle,
    cecAccountAddViewTitle,
    cecAccountTableViewTitle,
    cecAccountEditViewTitle,
    cecAccountDetailsViewTitle,
    scoAdminPrincipalMappingTitle,
    scoAdminPrincipalMappingEditTitle,
    shippingInstructionRequestListViewTitle,
    shippingInstructionRequestAddViewTitle,
    shippingInstructionRequestEditViewTitle,
    shippingInstructionRequestDetailsViewTitle,
    codeMappingListViewTitle,
		codeMappingAddViewTitle,
		codeMappingEditViewTitle,
		codeMappingDetailsViewTitle
  }
}

export default useBreadcrumbNameMap

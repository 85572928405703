import React, { createContext, useState, useMemo } from 'react'

export const BASIC_ITEM = {
  "hs_code": '',
  "type": '',
  "indent": 0.0,
  "hs_description": '',
  "uom": [],
  "str_confid": ''
}

export const ORIGIN = 'origin'
export const DESTINATION = 'destination'
export const DUTIABLE = 'dutiable'

export const TASContext = React.createContext(null)
TASContext.displayName = 'TASContext'

export function Context(props) {
  const [sessionToken, setSessionToken] = useState("")
  const [originToken, setOriginToken] = useState("")
  const [destinationToken, setDestinationToken] = useState("")
  const [isOriginLoading, setOriginLoading] = useState(false)
  const [isDestinationLoading, setDestinationLoading] = useState(false)
  const [requestType, setRequestType] = useState("both")

  const [activeDirection, setDirection] = useState(ORIGIN)

  const [copyAlerts, setCopyAlerts] = useState([])

  const [originTree, setOriginTree] = useState({
    active: BASIC_ITEM,
    expanded: [],
    data: null
  })
  const [destinationTree, setDestinationTree] = useState({
    active: BASIC_ITEM,
    expanded: [],
    data: null
  })

  const setTreeData = (data, direction = activeDirection) => getTree(direction, data)

  const getTree = (direction = activeDirection, data) => {
    if (direction === ORIGIN)
      return data ? setOriginTree(data) : originTree
    if (direction === DESTINATION)
      return data ? setDestinationTree(data) : destinationTree
    return data ? null : {}
  }

  const activeItem = useMemo(
    () => getTree().active || BASIC_ITEM,
    [activeDirection, originTree.active, destinationTree.active]
  )
  const activeTree = useMemo(
    () => getTree(),
    [activeDirection, originTree, destinationTree]
  )
  const setLoading = (isLoading, direction = activeDirection) => {
    if (direction === ORIGIN)
      setOriginLoading(isLoading)
    else
      setDestinationLoading(isLoading)
  }


  const copyCode = (hscode, key) => {
    let dummy = document.createElement("textarea")
    document.body.appendChild(dummy)
    dummy.value = hscode
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)

    setCopyAlerts([
      ...copyAlerts,
      {
        message: <span>HS code <b>{hscode}</b> copied to clipboard!</span>,
        key
      }
    ])
  }

  const resetDirection = (direction = activeDirection) => {
    setTreeData({
      active: BASIC_ITEM,
      expanded: [],
      data: null
    }, direction)
  }

  return (
    <TASContext.Provider
      value={{
        sessionToken, setSessionToken,
        originToken, setOriginToken,
        destinationToken, setDestinationToken,
        isOriginLoading, setOriginLoading,
        isDestinationLoading, setDestinationLoading,
        setLoading,

        activeDirection, setDirection, resetDirection,
        activeItem, activeTree, setTreeData,
        originTree, setOriginTree,
        destinationTree, setDestinationTree,
        requestType, setRequestType,
        copyAlerts, copyCode
      }}
    >
      {props.children}
    </TASContext.Provider>
  )
}

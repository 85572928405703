import { softShadows, strongShadows } from './shadows'

import { createMuiTheme, colors } from '@material-ui/core'
import { ThemeLibrary as CngThemeLibrary } from 'cng-web-lib'
import colorConfiguration from 'src/theme/colorConfiguration'

const LIGHT = 'LIGHT'
const ONE_DARK = 'ONE_DARK'
const UNICORN = 'UNICORN'

const base = createMuiTheme()

const props = {
  MuiTextField: {
    variant: 'outlined'
  },
  MuiButton: {
    disableElevation: true
  }
}

const SPACING = 8

const overrides = theme => ({
  MuiBreadcrumbs: {
    li: {
      '& .MuiLink-root': {
        fontWeight: 700
      }
    }
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      borderRadius: 8
    },
    iconSizeMedium: {
      '& > .svg-inline--fa:first-child': {
        fontSize: 'inherit'
      }
    },
    iconSizeLarge: {
      '& > .svg-inline--fa:first-child': {
        fontSize: 'inherit'
      }
    }
  },
  MuiIconButton: {
    root: {
      '&.cng-cia': {
        padding: SPACING,
        '&:not(.MuiSwitch-switchBase)': {
          height: '1.75em',
          width: '1.75em'
        }
      }
    }
  },
  MuiList: {
    root: {
      '&.ng-sidebar-list': {
        '& .MuiButton-root:not(.nav-depth-0).no-child': {
          fontWeight: 500,
          transition: base.transitions.create(),
          '&:hover': {
            '& > .MuiButton-label': {
              color: theme.palette.primary.main,
              '& > .nav-title': { color: theme.palette.text.cngBlack }
            }
          }
        },
        '& .MuiButton-root.has-child:not(.nav-collapsed) > .MuiButton-label': {
          '&, & > svg': {color: theme.palette.primary.main}
        }
      }
    }
  },
  MuiAutocomplete: {
    popupIndicator: {
      width: '1.25em',
      height: '1.25em',
      marginRight: SPACING * -0.75
    }
  },
  MuiPaper: {
    rounded: { borderRadius: 11 }
  }
})

class ThemeLibrary extends CngThemeLibrary {
  getNames() {
    // return [LIGHT, ONE_DARK, UNICORN]
    return [LIGHT]
  }

  getConfigurations() {
    const config = super.getConfigurations(colorConfiguration)
    console.log('config', config)
    config.map(d => {
      d.overrides = {...overrides(config[0]), ...d.overrides}
      return d;
    })
    return config;
  }

}

export default ThemeLibrary

export { LandedCostContext } from './lcc'
export { TASContext } from './tas'

// Get separated HS Code from string
export function getHSCode(code) {
  if (!code)
    return []
  let ret = []
  ret.push(code.slice(0,2))
  if (code.length > 2)
    ret.push(code.slice(2,4))
  if (code.length > 4)
    ret.push(code.slice(4,6))
  if (code.length > 6)
    ret.push(code.slice(6))

  return ret;
}

export const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
export const getMenuPrivileges = () => (JSON.parse(localStorage.getItem('menuPrivileges')) || [])

export const sortResultsForTree = result =>
  Object.values(result).map(({detail, ...children}) => ({
    id: Math.random() + '',
    ...detail,
    children: sortResultsForTree(children)
  }))

const SNACKBAR_OPTIONS = { variant: 'error', preventDuplicate: true }
const errorArray = (snackProvider, error) => {
  Object.entries(error).forEach(([key, msg]) => {
    if (msg instanceof Array) {
      msg.forEach(m => {
        let e = m
        if (m.hasOwnProperty('message'))
          e = m.message
        snackProvider(e, SNACKBAR_OPTIONS)
      })
    } else
      { snackProvider(msg, SNACKBAR_OPTIONS) }
  })
}
export const handleErrors = (snackProvider, error) => {
  console.log(error)
  if (!error)
    snackProvider('An unknown error occurred.', SNACKBAR_OPTIONS)
  else if (typeof error === 'string')
    snackProvider(error, SNACKBAR_OPTIONS)
  else if (error instanceof Error) {
    let errorObj = error
    if(error.response != null)
      errorObj = error.response.data
    if(errorObj.status === 400) {
      errorObj.errorDetails.forEach(e => {
        snackProvider(e.errorMessage, SNACKBAR_OPTIONS)
      })
    } else {
      snackProvider(error.toString(), SNACKBAR_OPTIONS)
    }
  }
  else if (error instanceof Array) {
      error.forEach(m => {
        console.log(error)
        let e = m
        if (m.hasOwnProperty('message'))
          e = m.message
        snackProvider(e, SNACKBAR_OPTIONS)
      })
  } else {
    errorArray(snackProvider, error)
  }
}

export const handleErrorsV2_1 = (snackProvider, error) => {
  console.log(error)
  if (!error)
    snackProvider('An unknown error occurred.', SNACKBAR_OPTIONS)
  else if (typeof error === 'string')
    snackProvider(error, SNACKBAR_OPTIONS)
  else if (error instanceof Error && error.response != null && error.response.status === 400) {
    error.response.data.details.forEach(e => {
        let msg = e.split("]: ")
        if (msg.length < 2) {
          snackProvider(e, SNACKBAR_OPTIONS)
        }
        else {
          snackProvider(msg[1], SNACKBAR_OPTIONS)
        }
    })
  } 
  else {
    snackProvider(error.toString(), SNACKBAR_OPTIONS)
  }
}



const UNITS = [
  [/(PERCENT)|(\%)/g, '%'],
  [/(LITERS)|(LITRES)/g, 'ℓ'],
  [/(KILOLITERS)|(KILOLITRES)/g, 'kl'],
  [/(KILOGRAMS)|(KILOGRAMMES)/g, 'kg'],
  [/(GRAMS)|(GRAMMES)/g, 'g'],
  [/(SQUARE METERS)|(SQUARE METRES)/g, 'm²'],
]

export const getUnit = uom => {
  let find = UNITS.find(([regexp, unit]) => uom.match(regexp))
  if (find)
    return find[1]
}

// For use with UOM response
export const getUOMSymbols = uomData => uomData.map(uom => ([uom, getUnit(uom)]))

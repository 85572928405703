import React, { createContext, useState, useEffect, useMemo } from 'react'
import { fetchCodes, IN, LIKE } from 'src/services'

export const LandedCostContext = React.createContext(null)
LandedCostContext.displayName = 'LandedCostContext'

export function Context(props) {
  const [formValuesMemory, setFormValuesMemory] = useState(null)
  const [displayResult, setDisplayResult] = useState(null)
  const [countryDetails, setCountryDetails] = useState(null)
  const [currencyOptions, setCurrencyOptions] = useState([])

  const [transactionCount, setTransactionCount] = useState(-1)
  const [editCriteria, setEditCriteria] = useState(false)

  useEffect(() => {
    if (formValuesMemory) {
      setCountryDetails({
        origin: {
          code: formValuesMemory.exportCountry,
          name: formValuesMemory.exportCountry_label
        },
        destination: {
          code: formValuesMemory.importCountry,
          name: formValuesMemory.importCountry_label
        },
        manufacturer: {
          code: formValuesMemory.manufactureOrigin,
          name: formValuesMemory.manufactureOrigin_label
        }
      })
    } else
      { setCountryDetails(null) }
  }, [formValuesMemory])

  const triggerTransaction = () => setTransactionCount(transactionCount + 1)

  return (
    <LandedCostContext.Provider
      value={{
        formValuesMemory, setFormValuesMemory,
        displayResult, setDisplayResult,
        currencyOptions, setCurrencyOptions,
        countryDetails,
        transactionCount, triggerTransaction,
        editCriteria, setEditCriteria
      }}
    >
      {props.children}
    </LandedCostContext.Provider>
  )
}
